/* theme variables */
/* layout(id=1, name=default) */
/* generated 2025-01-31T13:02:07+00:00 */

    /* Layout custom CSS */
    .logo-login {
    background-color: rgba(255, 255, 255, 1) !important;
    padding: 15px;
    border-radius: 30px;
    margin-bottom: 0;
}

/* Layout colors and font sizes */
:root {
    --body-bg: #ffffff !important;
    --primary: #e60000 !important;
    --primary-50: #fad1d1 !important;
    --primary-100: #f8baba !important;
    --primary-200: #f48b8b !important;
    --primary-300: #ef5d5d !important;
    --primary-400: #eb2e2e !important;
    --primary-600: #cc1a1a !important;
    --primary-700: #c62020 !important;
    --primary-800: #c02626 !important;
    --primary-900: #ba2c2c !important;
    --secondary: #929292 !important;
    --secondary-50: #ffffff !important;
    --secondary-100: #f8f8f8 !important;
    --secondary-200: #dfdfdf !important;
    --secondary-300: #c5c5c5 !important;
    --secondary-400: #ababab !important;
    --secondary-600: #787878 !important;
    --secondary-700: #5f5f5f !important;
    --secondary-800: #464646 !important;
    --secondary-900: #2c2c2c !important;
    --success: #28a745 !important;
    --success-50: #3f9051 !important;
    --success-100: #3d924f !important;
    --success-200: #38974c !important;
    --success-300: #329d49 !important;
    --success-400: #2da246 !important;
    --success-600: #34ce55 !important;
    --success-700: #5dd878 !important;
    --success-800: #86e29a !important;
    --success-900: #afecbc !important;
    --danger: #dc3545 !important;
    --danger-50: #fef9f9 !important;
    --danger-100: #fae3e5 !important;
    --danger-200: #f3b7bd !important;
    --danger-300: #eb8c95 !important;
    --danger-400: #e4606d !important;
    --danger-600: #d63b4a !important;
    --danger-700: #d0414f !important;
    --danger-800: #ca4753 !important;
    --danger-900: #c44d58 !important;
    --warning: #ffc107 !important;
    --warning-50: #e3b323 !important;
    --warning-100: #e6b520 !important;
    --warning-200: #ecb81a !important;
    --warning-300: #f3bb13 !important;
    --warning-400: #f9be0d !important;
    --warning-600: #ffce3a !important;
    --warning-700: #ffdb6d !important;
    --warning-800: #ffe7a0 !important;
    --warning-900: #fff4d3 !important;
    --light: #f8f9fa !important;
    --light-50: #f9f9f9 !important;
    --light-100: #f9f9f9 !important;
    --light-200: #f9f9f9 !important;
    --light-300: #f9f9f9 !important;
    --light-400: #f8f9fa !important;
    --light-600: #ffffff !important;
    --light-700: #ffffff !important;
    --light-800: #ffffff !important;
    --light-900: #ffffff !important;
    --dark: #343a40 !important;
    --dark-50: #3a3a3a !important;
    --dark-100: #3a3a3a !important;
    --dark-200: #3a3a3a !important;
    --dark-300: #3a3a3a !important;
    --dark-400: #373a3d !important;
    --dark-600: #4b545c !important;
    --dark-700: #626d78 !important;
    --dark-800: #7a8793 !important;
    --dark-900: #96a0aa !important;
    --button-primary: #e60000 !important;
    --button-primary-50: #fad1d1 !important;
    --button-primary-100: #f8baba !important;
    --button-primary-200: #f48b8b !important;
    --button-primary-300: #ef5d5d !important;
    --button-primary-400: #eb2e2e !important;
    --button-primary-600: #cc1a1a !important;
    --button-primary-700: #c62020 !important;
    --button-primary-800: #c02626 !important;
    --button-primary-900: #ba2c2c !important;
    --button-secondary: #ffffff !important;
    --button-secondary-50: #ffffff !important;
    --button-secondary-100: #ffffff !important;
    --button-secondary-200: #ffffff !important;
    --button-secondary-300: #ffffff !important;
    --button-secondary-400: #ffffff !important;
    --button-secondary-600: #e6e6e6 !important;
    --button-secondary-700: #cccccc !important;
    --button-secondary-800: #b3b3b3 !important;
    --button-secondary-900: #999999 !important;
    --button-success: #22c55e !important;
    --button-success-50: #3cab65 !important;
    --button-success-100: #39ae64 !important;
    --button-success-200: #33b462 !important;
    --button-success-300: #2eb961 !important;
    --button-success-400: #28bf5f !important;
    --button-success-600: #3ddd78 !important;
    --button-success-700: #68e596 !important;
    --button-success-800: #94ecb4 !important;
    --button-success-900: #bff4d2 !important;
    --button-danger: #b91c1c !important;
    --button-danger-50: #a13434 !important;
    --button-danger-100: #a43131 !important;
    --button-danger-200: #a92c2c !important;
    --button-danger-300: #ae2727 !important;
    --button-danger-400: #b42121 !important;
    --button-danger-600: #df2929 !important;
    --button-danger-700: #e55656 !important;
    --button-danger-800: #ec8282 !important;
    --button-danger-900: #f3aeae !important;
    --button-warning: #f59e0b !important;
    --button-warning-50: #d89528 !important;
    --button-warning-100: #dc9524 !important;
    --button-warning-200: #e2971e !important;
    --button-warning-300: #e89818 !important;
    --button-warning-400: #ef9a11 !important;
    --button-warning-600: #f7af3c !important;
    --button-warning-700: #f9c36d !important;
    --button-warning-800: #fbd79e !important;
    --button-warning-900: #fdebcf !important;
    --button-light: #f1f5f8 !important;
    --button-light-50: #f3f5f6 !important;
    --button-light-100: #f3f5f6 !important;
    --button-light-200: #f3f5f6 !important;
    --button-light-300: #f2f5f7 !important;
    --button-light-400: #f2f5f7 !important;
    --button-light-600: #ffffff !important;
    --button-light-700: #ffffff !important;
    --button-light-800: #ffffff !important;
    --button-light-900: #ffffff !important;
    --button-dark: #121517 !important;
    --button-dark-50: #151515 !important;
    --button-dark-100: #151515 !important;
    --button-dark-200: #151515 !important;
    --button-dark-300: #141515 !important;
    --button-dark-400: #131516 !important;
    --button-dark-600: #282f34 !important;
    --button-dark-700: #3f4950 !important;
    --button-dark-800: #55636d !important;
    --button-dark-900: #6c7d89 !important;
    --button-primary-hover: #c60000 !important;
    --button-primary-hover-50: #f0bcbc !important;
    --button-primary-hover-100: #eba7a7 !important;
    --button-primary-hover-200: #e27d7d !important;
    --button-primary-hover-300: #d95353 !important;
    --button-primary-hover-400: #cc2d2d !important;
    --button-primary-hover-600: #9d2929 !important;
    --button-primary-hover-700: #982e2e !important;
    --button-primary-hover-800: #933333 !important;
    --button-primary-hover-900: #8e3838 !important;
    --button-secondary-hover: #d4d4d4 !important;
    --button-secondary-hover-50: #ffffff !important;
    --button-secondary-hover-100: #ffffff !important;
    --button-secondary-hover-200: #ffffff !important;
    --button-secondary-hover-300: #ffffff !important;
    --button-secondary-hover-400: #eeeeee !important;
    --button-secondary-hover-600: #bbbbbb !important;
    --button-secondary-hover-700: #a1a1a1 !important;
    --button-secondary-hover-800: #888888 !important;
    --button-secondary-hover-900: #6e6e6e !important;
    --button-success-hover: #036d40 !important;
    --button-success-hover-50: #10603d !important;
    --button-success-hover-100: #0e623e !important;
    --button-success-hover-200: #0b653e !important;
    --button-success-hover-300: #09673e !important;
    --button-success-hover-400: #066a3f !important;
    --button-success-hover-600: #049f5c !important;
    --button-success-hover-700: #06d079 !important;
    --button-success-hover-800: #11f894 !important;
    --button-success-hover-900: #42faaa !important;
    --button-danger-hover: #a71919 !important;
    --button-danger-hover-50: #912f2f !important;
    --button-danger-hover-100: #942c2c !important;
    --button-danger-hover-200: #992727 !important;
    --button-danger-hover-300: #9d2323 !important;
    --button-danger-hover-400: #a21e1e !important;
    --button-danger-hover-600: #d32020 !important;
    --button-danger-hover-700: #e34343 !important;
    --button-danger-hover-800: #ea6f6f !important;
    --button-danger-hover-900: #f09c9c !important;
    --button-warning-hover: #d59c21 !important;
    --button-warning-hover-50: #b9923d !important;
    --button-warning-hover-100: #bc933a !important;
    --button-warning-hover-200: #c39533 !important;
    --button-warning-hover-300: #c9972d !important;
    --button-warning-hover-400: #cf9a27 !important;
    --button-warning-hover-600: #e2b147 !important;
    --button-warning-hover-700: #e9c473 !important;
    --button-warning-hover-800: #f0d69f !important;
    --button-warning-hover-900: #f7e9cb !important;
    --button-light-hover: #edeeee !important;
    --button-light-hover-50: #eeeeee !important;
    --button-light-hover-100: #eeeeee !important;
    --button-light-hover-200: #eeeeee !important;
    --button-light-hover-300: #eeeeee !important;
    --button-light-hover-400: #eeeeee !important;
    --button-light-hover-600: #ffffff !important;
    --button-light-hover-700: #ffffff !important;
    --button-light-hover-800: #ffffff !important;
    --button-light-hover-900: #ffffff !important;
    --button-dark-hover: #1d2024 !important;
    --button-dark-hover-50: #212121 !important;
    --button-dark-hover-100: #212121 !important;
    --button-dark-hover-200: #212121 !important;
    --button-dark-hover-300: #202021 !important;
    --button-dark-hover-400: #1f2022 !important;
    --button-dark-hover-600: #343940 !important;
    --button-dark-hover-700: #4b525c !important;
    --button-dark-hover-800: #616b79 !important;
    --button-dark-hover-900: #7a8593 !important;
    --button-primary-text: #ffffff !important;
    --button-primary-text-50: #8c8c8c !important;
    --button-primary-text-100: #999999 !important;
    --button-primary-text-200: #b3b3b3 !important;
    --button-primary-text-300: #cccccc !important;
    --button-primary-text-400: #e6e6e6 !important;
    --button-primary-text-600: #ffffff !important;
    --button-primary-text-700: #ffffff !important;
    --button-primary-text-800: #ffffff !important;
    --button-primary-text-900: #ffffff !important;
    --button-secondary-text: #000000 !important;
    --button-secondary-text-50: #737373 !important;
    --button-secondary-text-100: #666666 !important;
    --button-secondary-text-200: #4d4d4d !important;
    --button-secondary-text-300: #333333 !important;
    --button-secondary-text-400: #1a1a1a !important;
    --button-secondary-text-600: #000000 !important;
    --button-secondary-text-700: #000000 !important;
    --button-secondary-text-800: #000000 !important;
    --button-secondary-text-900: #000000 !important;
    --button-success-text: #ffffff !important;
    --button-success-text-50: #8c8c8c !important;
    --button-success-text-100: #999999 !important;
    --button-success-text-200: #b3b3b3 !important;
    --button-success-text-300: #cccccc !important;
    --button-success-text-400: #e6e6e6 !important;
    --button-success-text-600: #ffffff !important;
    --button-success-text-700: #ffffff !important;
    --button-success-text-800: #ffffff !important;
    --button-success-text-900: #ffffff !important;
    --button-danger-text: #ffffff !important;
    --button-danger-text-50: #8c8c8c !important;
    --button-danger-text-100: #999999 !important;
    --button-danger-text-200: #b3b3b3 !important;
    --button-danger-text-300: #cccccc !important;
    --button-danger-text-400: #e6e6e6 !important;
    --button-danger-text-600: #ffffff !important;
    --button-danger-text-700: #ffffff !important;
    --button-danger-text-800: #ffffff !important;
    --button-danger-text-900: #ffffff !important;
    --button-warning-text: #ffffff !important;
    --button-warning-text-50: #8c8c8c !important;
    --button-warning-text-100: #999999 !important;
    --button-warning-text-200: #b3b3b3 !important;
    --button-warning-text-300: #cccccc !important;
    --button-warning-text-400: #e6e6e6 !important;
    --button-warning-text-600: #ffffff !important;
    --button-warning-text-700: #ffffff !important;
    --button-warning-text-800: #ffffff !important;
    --button-warning-text-900: #ffffff !important;
    --button-light-text: #212529 !important;
    --button-light-text-50: #252525 !important;
    --button-light-text-100: #252525 !important;
    --button-light-text-200: #252525 !important;
    --button-light-text-300: #252525 !important;
    --button-light-text-400: #232527 !important;
    --button-light-text-600: #383f45 !important;
    --button-light-text-700: #4e5862 !important;
    --button-light-text-800: #65727e !important;
    --button-light-text-900: #7e8b98 !important;
    --button-dark-text: #ffffff !important;
    --button-dark-text-50: #8c8c8c !important;
    --button-dark-text-100: #999999 !important;
    --button-dark-text-200: #b3b3b3 !important;
    --button-dark-text-300: #cccccc !important;
    --button-dark-text-400: #e6e6e6 !important;
    --button-dark-text-600: #ffffff !important;
    --button-dark-text-700: #ffffff !important;
    --button-dark-text-800: #ffffff !important;
    --button-dark-text-900: #ffffff !important;
    --button-primary-text-hover: #ffffff !important;
    --button-primary-text-hover-50: #8c8c8c !important;
    --button-primary-text-hover-100: #999999 !important;
    --button-primary-text-hover-200: #b3b3b3 !important;
    --button-primary-text-hover-300: #cccccc !important;
    --button-primary-text-hover-400: #e6e6e6 !important;
    --button-primary-text-hover-600: #ffffff !important;
    --button-primary-text-hover-700: #ffffff !important;
    --button-primary-text-hover-800: #ffffff !important;
    --button-primary-text-hover-900: #ffffff !important;
    --button-secondary-text-hover: #000000 !important;
    --button-secondary-text-hover-50: #737373 !important;
    --button-secondary-text-hover-100: #666666 !important;
    --button-secondary-text-hover-200: #4d4d4d !important;
    --button-secondary-text-hover-300: #333333 !important;
    --button-secondary-text-hover-400: #1a1a1a !important;
    --button-secondary-text-hover-600: #000000 !important;
    --button-secondary-text-hover-700: #000000 !important;
    --button-secondary-text-hover-800: #000000 !important;
    --button-secondary-text-hover-900: #000000 !important;
    --button-success-text-hover: #ffffff !important;
    --button-success-text-hover-50: #8c8c8c !important;
    --button-success-text-hover-100: #999999 !important;
    --button-success-text-hover-200: #b3b3b3 !important;
    --button-success-text-hover-300: #cccccc !important;
    --button-success-text-hover-400: #e6e6e6 !important;
    --button-success-text-hover-600: #ffffff !important;
    --button-success-text-hover-700: #ffffff !important;
    --button-success-text-hover-800: #ffffff !important;
    --button-success-text-hover-900: #ffffff !important;
    --button-danger-text-hover: #ffffff !important;
    --button-danger-text-hover-50: #8c8c8c !important;
    --button-danger-text-hover-100: #999999 !important;
    --button-danger-text-hover-200: #b3b3b3 !important;
    --button-danger-text-hover-300: #cccccc !important;
    --button-danger-text-hover-400: #e6e6e6 !important;
    --button-danger-text-hover-600: #ffffff !important;
    --button-danger-text-hover-700: #ffffff !important;
    --button-danger-text-hover-800: #ffffff !important;
    --button-danger-text-hover-900: #ffffff !important;
    --button-warning-text-hover: #ffffff !important;
    --button-warning-text-hover-50: #8c8c8c !important;
    --button-warning-text-hover-100: #999999 !important;
    --button-warning-text-hover-200: #b3b3b3 !important;
    --button-warning-text-hover-300: #cccccc !important;
    --button-warning-text-hover-400: #e6e6e6 !important;
    --button-warning-text-hover-600: #ffffff !important;
    --button-warning-text-hover-700: #ffffff !important;
    --button-warning-text-hover-800: #ffffff !important;
    --button-warning-text-hover-900: #ffffff !important;
    --button-light-text-hover: #000000 !important;
    --button-light-text-hover-50: #000000 !important;
    --button-light-text-hover-100: #000000 !important;
    --button-light-text-hover-200: #000000 !important;
    --button-light-text-hover-300: #000000 !important;
    --button-light-text-hover-400: #000000 !important;
    --button-light-text-hover-600: #1a1a1a !important;
    --button-light-text-hover-700: #333333 !important;
    --button-light-text-hover-800: #4d4d4d !important;
    --button-light-text-hover-900: #666666 !important;
    --button-dark-text-hover: #ffffff !important;
    --button-dark-text-hover-50: #8c8c8c !important;
    --button-dark-text-hover-100: #999999 !important;
    --button-dark-text-hover-200: #b3b3b3 !important;
    --button-dark-text-hover-300: #cccccc !important;
    --button-dark-text-hover-400: #e6e6e6 !important;
    --button-dark-text-hover-600: #ffffff !important;
    --button-dark-text-hover-700: #ffffff !important;
    --button-dark-text-hover-800: #ffffff !important;
    --button-dark-text-hover-900: #ffffff !important;
    --header-background: #ffffff !important;
    --header-text-color: #212529 !important;
    --header-link-color: #212529 !important;
    --header-link-hover-color: #e60000 !important;
    --navbar-background: #ffffff !important;
    --navbar-popout-background: #ffffff !important;
    --navbar-text-color: #000000 !important;
    --navbar-link-color: #000000 !important;
    --navbar-link-hover-color: #e60000 !important;
    --navbar-popout-link-color: #000000 !important;
    --navbar-popout-link-hover-color: #e60000 !important;
    --footer-background: #262626 !important;
    --footer-text-color: #ffffff !important;
    --footer-link-color: #ffffff !important;
    --footer-link-hover-color: #d3d3d3 !important;

    --font-size-default: 0.875rem !important;
    --font-size-small: 0.75rem !important;
    --font-size-medium: 1rem !important;
    --font-size-large: 1.25rem !important;
    --font-size-extra-large: 1.5rem !important;
    --font-size-huge: 2rem !important;
    --font-size-headline: 3rem !important;
    --font-size-subhead: 2.5rem !important;
    --font-size-section-header: 2rem !important;
    --font-size-section-subheader: 1.5rem !important;
    --font-size-site-header: 3rem !important;
    --font-size-footer-title: 1.25rem !important;
    --font-size-footer: 0.875rem !important;
}

/* Legacy classes */
        .has-primary-color {
        color: var(--primary) !important;
    }
        .has-primary-background-color {
        background-color: var(--primary) !important;
    }
        .has-primary-fill {
        fill: var(--primary) !important;
    }
        .has-primary-stroke {
        stroke: var(--primary) !important;
    }
            .has-secondary-color {
        color: var(--secondary) !important;
    }
        .has-secondary-background-color {
        background-color: var(--secondary) !important;
    }
        .has-secondary-fill {
        fill: var(--secondary) !important;
    }
        .has-secondary-stroke {
        stroke: var(--secondary) !important;
    }
            .has-success-color {
        color: var(--success) !important;
    }
        .has-success-background-color {
        background-color: var(--success) !important;
    }
        .has-success-fill {
        fill: var(--success) !important;
    }
        .has-success-stroke {
        stroke: var(--success) !important;
    }
            .has-danger-color {
        color: var(--danger) !important;
    }
        .has-danger-background-color {
        background-color: var(--danger) !important;
    }
        .has-danger-fill {
        fill: var(--danger) !important;
    }
        .has-danger-stroke {
        stroke: var(--danger) !important;
    }
            .has-warning-color {
        color: var(--warning) !important;
    }
        .has-warning-background-color {
        background-color: var(--warning) !important;
    }
        .has-warning-fill {
        fill: var(--warning) !important;
    }
        .has-warning-stroke {
        stroke: var(--warning) !important;
    }
            .has-light-color {
        color: var(--light) !important;
    }
        .has-light-background-color {
        background-color: var(--light) !important;
    }
        .has-light-fill {
        fill: var(--light) !important;
    }
        .has-light-stroke {
        stroke: var(--light) !important;
    }
            .has-dark-color {
        color: var(--dark) !important;
    }
        .has-dark-background-color {
        background-color: var(--dark) !important;
    }
        .has-dark-fill {
        fill: var(--dark) !important;
    }
        .has-dark-stroke {
        stroke: var(--dark) !important;
    }
    
.has-default-font-size {
    font-size: var(--font-size-default) !important;
}
.has-small-font-size {
    font-size: var(--font-size-small) !important;
}
.has-medium-font-size {
    font-size: var(--font-size-medium) !important;
}
.has-large-font-size {
    font-size: var(--font-size-large) !important;
}
.has-extra-large-font-size {
    font-size: var(--font-size-extra-large) !important;
}
.has-huge-font-size {
    font-size: var(--font-size-huge) !important;
}
.has-headline-font-size {
    font-size: var(--font-size-headline) !important;
}
.has-subhead-font-size {
    font-size: var(--font-size-subhead) !important;
}
.has-section-header-font-size {
    font-size: var(--font-size-section-header) !important;
}
.has-section-subheader-font-size {
    font-size: var(--font-size-section-subheader) !important;
}
.has-site-header-font-size {
    font-size: var(--font-size-site-header) !important;
}
.has-footer-title-font-size {
    font-size: var(--font-size-footer-title) !important;
}
.has-footer-font-size {
    font-size: var(--font-size-footer) !important;
}
